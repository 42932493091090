.box-slider {
    .pageslider {
        .slides {
            li {
                background-size: cover!important;  
            }
        }

        .ps-arrow-left, .ps-arrow-right {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            margin-top: -2.5em;
            background: #fff!important;
            box-shadow: 0px 0px 55px -10px rgba(0,0,0,0.65);
            display: flex;
            justify-content: center;
            align-content: center;
            @media screen and (max-width: 767px) {
                width: 40px;
                height: 40px;
                margin-top: -1.5em;
            }
        }
        .ps-arrow-left {
            left: 1.5em!important;
            &::before {
                content: '\e911';
                font-family: Frino!important;
                color: @greenColor;
                line-height: 1;
                font-size: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
        .ps-arrow-right { 
            right: 1.5em!important; 
            &::before {
                content: '\e912';
                font-family: Frino!important;
                color: @greenColor;
                line-height: 1;
                font-size: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        } 
 
        .slider-nav {
            &.bullets {
                li {
                    background: transparent;  
                    border: 1px solid #fff;
                    width: 12px;
                    height: 12px; 
                    &.active {
                        background: @yellowColor; 
                        border-color: @yellowColor;
                    } 
                }
            }
        }
    }
}