.tab-container {
    margin: 60px 0;
    
    nav {
        background: @greenColor;
        margin-bottom: 0;
        ul {
            border-bottom: 0;
            li {
                padding: 0;
                .boxhead {
                    padding: 18px 45px; 
                    h3 {
                        font-size: 18px;
                        font-weight: bold;
                        color: #fff;
                    }
                    &.active {
                        border: 0;
                        background: @yellowColor;
                        box-shadow: 0px 3px 13px 0px rgba(1, 1, 1, 0.2);
                        padding: 18px 45px; 
                        h3 {
                            color: #000;
                        }
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .product-tabs {
        background: #fff;
        .product-tab {
            .innerbox {
                padding: 35px;
                p {
                    font-size: 14px;
                    line-height: 1.5;
                }
            }
            .product-attributes {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .table {
                    border: 1px solid @lightGreyColor;
                    max-width: 900px;
                    td {
                        padding: 10px;
                    }
                }
            }
        }
    }
}
  