.footer {
    background: url("/skins/user/rwd_shoper_2/images/footerbg.png");
    background-position: top center;
    background-color: @greenColor;
    padding-bottom: 0;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
        margin: 0!important;
    }
    .innerfooter {
        color: #fff;
        ul.overall {
            padding: 0 45px;
            li.overall {
                border-top: 0;
                padding-top: 3.5em;
                padding-bottom: 3.5em;
                &:nth-child(2) {
                    width: 23%;
                }
                &:nth-child(3) {
                    width: 14%;
                } 
                &:nth-child(4) {
                    width: 23%;
                } 
                @media screen and (max-width: 767px) {
                    width: 100%!important;
                    padding: 1.5em 0;
                }

            }
            ul.links {
              .links__header {
                  background-color: @greenColor; 
                  text-transform: uppercase;
                  font-size: 16px;
              }  
              .link {
                  padding: .3em 0;
                  > a {
                      color: #fff; 
                      font-size: 14px;
                  }  
                  @media screen and (max-width: 767px) {
                      background-color: @greenColor;
                  }
              }
            }
        }
    }
} 