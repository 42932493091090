#productstab {
    flex-basis: calc(100% / 2);
    max-width: 50%;
    @media screen and (max-width: 1150px) {
        flex-basis: calc(100% / 1);
        max-width: 100%;
    }
    ul {
        &.tabs {
            margin: 0px;
            padding: 0 0 0 30px;
            list-style: none;
            background: @greenColor;
            @media screen and (max-width: 610px) {
                padding: 0;
            }
            @media screen and (max-width: 535px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            li {
                color: #fff;
                display: inline-block;
                padding: 27px 45px;
                font-weight: bold;
                font-size: 16px;
                cursor: pointer;
                @media screen and (max-width: 1310px) {
                    padding: 27px 25px;
                }
                @media screen and (max-width: 610px) {
                    font-size: 14px;
                }
                &.current{
                    background: @yellowColor;
                    color: #fff;
                    box-shadow: 0px 3px 13px 0px rgba(1, 1, 1, 0.2);
                }
            }
        }
    }
    
    .tab-content{
        display: none;
        background: @backgroundBlock;
        padding: 0 30px;
        margin-top: 2rem;
        &.current{
            display: block;
        }
    }

}