#box_productfull {
    .productimg {
       // max-width: 550px;
       // width: 100%;
        background: #fff;
        padding: 30px 55px;
    } 
      
    .gallery {
        position: relative;
        padding-left: 6.6rem;
        max-height: 475px;
        @media screen and (max-width: 480px) {
            padding-left: 0;
        }
        &__thumbnails {
            position: absolute;
            top: 0;
            left: 0;
            width: 5rem;
            height: 100%;
            @media screen and (max-width: 480px) {
                display: none;
            }
            .thumbnails {
                position: absolute;
                top: 0;
                left: 0;
                width: 5rem;
                height: 100%;
                max-height: 345px;
                margin-top: 4rem;
                overflow: hidden;
                direction: rtl;
                &:hover {
                    //overflow-y: auto;
                }
                &::-webkit-scrollbar
                {
                    width: 3px; 
                    background-color: @lightGreyColor; 
                }
                &::-webkit-scrollbar-track
                {
                    -webkit-box-shadow: inset 0 0 6px @lightGreyColor;
                    border-radius: 10px;
                    background-color: @lightGreyColor;
                    border-left: 3px solid white;
                    border-right: 3px solid white;
                }
                &::-webkit-scrollbar-thumb
                {
                    border-radius: 20px;
                    -webkit-box-shadow: inset 0 0 px @greenColor;
                    background-color: @greenColor;
                
                }

                .thumbnail { 
                    position: relative;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0;
                    border: 1px solid transparent;
                    margin-bottom: 0;
                    transition: border .3s ease-in-out;
                    cursor: grab;
                    &.active {
                        border: 1px solid @greenColor; 
                    }

                    .thumbnail__wrapper {
                        height: 0;
                        display: block;
                        position: relative;
                        padding-top: calc(100% * (831 / 827));

                        .thumbnail__image {
                            position: absolute!important;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            padding: 5px;
                            object-fit: contain;
                        }
                    }
                }
            }
        }

        &__image {
            position: relative;
            width: 100%;
            .main-image__wrapper {
                height: 0;
                border: 0;
                padding-top: 430px;
                //padding-top: calc(100%*(831 / 1675));
                > .main-image {
                    position: absolute!important;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    outline: 0;
                    > .photo {
                        position: absolute!important;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        padding: 2.5rem;
                        object-fit: contain;
                    }
                }
                &.mainimg {
                    //cursor: crosshair;
                }

            }
        }


        &__carousel {
            .owl-nav {
                display: flex;
                justify-content: center;
                display: flex;
                justify-content: center;
                position: absolute;
                bottom: 5px;
                left: 0;
                right: 0;
                .owl-prev, .owl-next, .resize-image {
                    display: inline-block; 
                    vertical-align: middle;
                    width: 40px;
                    height: 40px;
                    background: #fff;
                    box-shadow: 0px 3px 16px 0px rgba(1, 1, 1, 0.24);
                    color: @greenColor;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 40px;
                    text-align: center;
                    background: #ffffff; 
                    border-radius: 50%;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        background: @greenColor;
                        color: #fff;
                    }
                }
                .owl-prev {
                    margin-right: 4px;
                }
                .owl-next {
                    margin-left: 4px;
                    margin-right: 4px;
                }
                .resize-image {
                    margin-left: 4px;
                    font-size: 14px;
                }
            }
        }

        .product__tags {
            color: @backgroundColor;
            position: absolute;
            top: 60px;
            z-index: 1;
            .tags {
                display: flex;
                flex-direction: column;
                padding-left: 0;
                .tag {
                    border-radius: 50%;
                    width: 45px;
                    height: 45px;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 14px;
                    margin-bottom: 10px;
                    &--sale {
                        background: @redColor;
                    }
                    &--new { 
                        background: @yellowColor; 
                    }
                }
            }
        }


    }
}
