.mainheader__languages {
    display: flex;
    flex-direction: column;
    width: 140px;
    margin: 0 15px;
    @media screen and (max-width: 1205px) {
        margin: 0;
    }
    .dropdown-wrapper {
        width: 100%;
        margin-top: 5px;
        position: relative;
        .language-dropdown {
            position: relative; 
            &::after {
                content: "\e910";
                font-family: Frino!important;
                font-size: 16px;
                font-weight: bold;
                position: absolute;
                top: 6px;
                right: 5px;
            }
            .language-select {
                display: flex;
                height: 29px;
                padding: 5px;
                position: relative;
                border-bottom: 1px solid @lightColor;
                font-size: 11px;
                cursor: pointer;
                .language-select-content {
                    pointer-events: none;
                }
            }
            .dropdown-menu {
                background: #fff;
                box-shadow: none;
                border-radius: 0px;
                position: absolute;
                width: 100%;
                max-width: 140px;
                z-index: 1;
                top: 40px; 
                list-style-type: none;
                font-size: 11px;
                li {
                    cursor: pointer;
                    padding:5px;
                }
            }
        }
        .language-disabled {
            pointer-events: none;
        }
        .language-hide {
            display:none;
        }
    }

}
