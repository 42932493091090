.centercol {
    &--listgrid {
        width: 100%;
        margin-left: 0;
        padding: 0 30px 0 50px;
        border-top: 1px solid @borderColor2;
        .box_mainproducts {
            .boxhead {
                border-bottom: 0;
                &--wrapper {
                    display: flex;
                    justify-content: space-between;
                    @media screen and (max-width: 980px) {
                        flex-direction: column;
                        align-items: center;
                    }
                    .sort-and-view {
                        display: flex;
                        justify-content: flex-end;
                        flex-basis: calc(100% / 3);
                        padding-top: 25px;
                        padding-right: 20px;
                        .products-active-sort {
                            background: #ffffff;
                            color: #000000;
                            border: 0;
                            height: 40px;
                            overflow: hidden;
                            &::after {
                                content: "\e910";
                                font-family: Frino!important;
                                color: #fff;
                                font-size: 16px;
                                font-weight: bold;
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding: 12px;
                                background: @yellowColor;
                                height: 40px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
            .innerbox {
                display: flex;
                flex-direction: column;
            }
        }
    }
}