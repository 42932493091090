.slider {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 2rem;

    .paginator {
        display: none;
    }

    .product {
        padding: 0;
    }
}


.slider--products {
    max-width: 100%;
    margin-top: 1rem;
   // padding: 0 30px;

    .owl-nav {
        width: 100%;
    }
    .owl-prev, .owl-next {
        position: absolute;
        top: calc(50% - 35px);
        display: inline-block; 
        vertical-align: middle;
        width: 40px;
        height: 40px;
        background: @greenColor!important;
        box-shadow: 0px 3px 16px 0px rgba(1, 1, 1, 0.24);
        color: #fff!important;
        font-size: 18px!important;
        line-height: 40px;
        text-align: center;
        background: #ffffff; 
        border-radius: 50%!important;
        margin: 0!important;
        transition: all 0.3s ease-in-out;
    }
    .owl-prev {
        left: 10px;
    }
    .owl-next {
        right: 10px;
    }
}

