.mainheader {
    &__login {
        @media screen and (max-width: 1405px) {
            display: none;
        }
        .login {
            display: flex;
            align-items: center;
            background: @yellowColor;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            padding: 11px 12px;
            margin: 0 15px;
            &::before {
                content: "\e746";
                font-family: Frino!important;
                font-size: 16px;
                padding-right: 10px;
            }
        }
    }
    &__actions {
        position: relative;
        background-color: @veryLightGreyColor;
        display: flex; 
        align-items: center;
        justify-content: center;
        min-height: 80px;
        width: 275px;
        margin: 0 15px;
        @media screen and (max-width: 1250px) {
            width: 205px;
        }
        .action {
            position: relative;
            display: flex;
            justify-content: center;
            flex-basis: calc(100% / 3);
            padding: 10px 0;
            text-align: center; 
            line-height: 1;
            a {
                font-size: 26px;
                position: relative;
            }
            &--search { 
                .search-form {
                    display: none!important;
                    &--visible {
                        display: block!important;
                        position: absolute;
                        top: 65px;
                        left: -165px;
                        z-index: 1;

                        @media screen and (max-width: 1150px) {
                            top: 65px;
                            left: 0;
                            z-index: 19;
                            background: @yellowColor;
                            width: 360px;
                        }
                    }
                    .search-input {
                        margin: 0;
                        height: 40px;
                        @media screen and (max-width: 1150px) {
                            width: 260px;
                        }
                    }
                    .search-btn {
                        height: 40px;
                        text-transform: uppercase;
                        padding: 0 10px;
                        font-size: 14px;
                    }
                }
            }
            &--account {
                border-left: 1px solid @lightColor;
                border-right: 1px solid @lightColor; 
                a {
                    font-size: 29px;
                }
            }
            &--basket {
                .countlabel {
                    .count {
                        position: absolute;
                        background: @greenColor;
                        top: -5px;
                        right: -10px;
                        color: #fff;
                        font-size: 12px;
                        border-radius: 50%;
                        padding: 3px 6px;
                    }
                }
            }
        }
    }
}
