.section {
    &__header {
        padding: 25px 0;
        position: relative;
        &::before {
            content: "";
            width: 105px;
            height: 5px;
            background: @yellowColor;
            position: absolute;
            top: 75px;
            right: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &--category {
            flex-basis: calc(100% / 3);
        }
        .section__title {
            text-align: center;
            font-size: 27px;
            margin: 0;
        }
    }
}
