.breadcrumbs {
    padding: 25px 0 25px;
    background: @backgroundBlock;
    .innerbreadcrumbs {
        padding: 0 30px;
        display: flex;
        align-items: center;
        .raq {
            display: none;
        }
        .breadcrumb-home {
            padding: 0 7px;
            font-size: 14px;
            line-height: 1;
            border-right: 1px solid #000000;
            img {
                display: none;
            }
            span {
                display: block;
               
            }
        }
        .path {
            li {
                font-size: 14px;
                border-right: 1px solid #000000;
                line-height: 1;
                padding: 0 7px;
                &.last {
                    border-right: 0;
                    padding: 10px 20px; 
                    font-weight: bold;
                    margin: 0 5px;
                    background: @greenColor;
                    color: #fff;
                }
                span {
                    line-height: 1;
                }

            }
        }
    }
}
