.mainheader {
    border-top: 0!important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    padding-left: 0;
    background: #fff;


    @media screen and (max-width: 1150px) {
        flex-direction: column;
        justify-content: center;
        padding-right: 0;
    }

    @media screen and (max-width: 767px) {
        padding: 0!important;
    }
    &__column {  
        display: flex;
        align-items: center;

        @media screen and (max-width: 1150px) {
            &:nth-child(1) {
                width: 100%;
                justify-content: space-between;
                align-items: center;
            }
            &:nth-child(2) {
               // order: -1;
                width: 100%;
                background-color: @veryLightGreyColor;
                justify-content: center;
            }
        }

        @media screen and (max-width: 767px) {
            &:nth-child(1) {
                flex-direction: column;
            }
        }

    }
}  
header {
    border-top: 0; 
    padding: 0;
}