html {
    font-size: @fontSize;
}
body {
    &.shop_index, &.shop_product, &.shop_product_list {
        background-color:  @backgroundBlock;
    }
    
    background-color: white;
   

}

*,
::after,
::before {
    box-sizing: border-box;
}

a {
    transition: opacity 0.3s ease-in-out;
    outline: 0;
    &:hover {
        opacity: 0.7;
    }
}

img {
    max-width: 100%;
    width: auto;
    height: auto;
}

::selection {
    background: @mainColor;
    color: @backgroundColor;
    opacity: 1;
}
 
.select-wrapper {
    position: relative;
    select {
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        cursor:pointer;
        background: none;
    }
}