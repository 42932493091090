.paginator-wrapper {
    text-align: right;
    .paginator {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin-top: 2em;
        li {
            margin: 0 0.3em;
            span, a {
                font-size: 14px;
                padding: 0.6em 1em;
                border: 0;
                background: #ffffff;
                color: #000000;
                font-weight: bold;
            }
            &.selected {
                span, a {
                    background: @greenColor;
                    color: #ffffff;
                }
            }
            &.first, &.last {
                span, a {
                    font-size: 20px;
                    background: transparent;
                    color: @greenColor;
                    padding: 0;
                }
            }
        }
    }
}