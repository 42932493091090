.mask {
    background-color: rgba(0, 0, 0, .9);
    background-image: none!important;
}


.shop-gallery {
    .shop-gallery-skin {
        @media screen and (min-width: 768px) {
            min-width: 600px;
        }
        .shop-gallery-title {
            padding: 12px;
        }
        .shop-gallery-inner {
            border-top: 0;
        }
    }
    .shop-gallery-preview {
        max-width: 600px;
        height: 125px;
        padding: 0;
        background-color: transparent;
        .shop-gallery-container {
            .shop-gallery-slider {
                .shop-gallery-item-preview {
                    width: 130px;
                    height: 110px;
                    margin-right: 9px;
                    margin-left: 7px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background: white;
                    position: relative;
                    &:last-child {
                        display: none;
                    }
                    &.active {
                        border: 0;
                        &::after {
                            content: '';
                            width: 130px;
                            height: 8px;
                            background: @yellowColor;
                            display: block;
                            position: absolute;
                            bottom: -12px;
                        }
                    }
                }
            }
        }
    }
}
