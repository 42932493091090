#news-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    position: relative;
    flex-basis: calc(100% / 2);

    @media screen and (max-width: 1150px) {
        width: 100%;
        flex-basis: calc(100% / 1);
    }
    
    img {
        width: 100%;
      //  padding-right: 25px;
        object-fit: cover;
        height: 100%;
        @media screen and (max-width: 480px) {
            min-height: 350px;
        }
    }
}

.news-banner__header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .news-banner__title {
        font-size: 3.5rem;
        color: #fff;
        font-weight: normal;
        text-align: center; 
        margin: 0 0 3rem;
        position: relative;
        &::after {
            content: "";
            width: 105px;
            height: 5px;
            background: @yellowColor;
            position: absolute;
            top: 75px;
            left: 35%;
        }
        @media screen and (max-width: 480px) {
            font-size: 2rem;
            &::after {
                top: 55px;
                left: 20%;
            }
        }
    }
    .news-banner__button {
        background-color: @yellowColor;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
        border: 0;
        line-height: 1.2;
        padding: 1.2em 2em;
        transition: .5s ease;
        &:hover {
            transition: .5s ease;
            box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.85);
            opacity: 1;
        }
    }
}