.product-list {
    display: flex;
    background: @backgroundBlock;
    .centercol {
        //width: 100%;
    }
    .leftcol {
        max-width: 310px;
        min-width: 310px;
        background:  @categoryBg;
        color: #000000;
        @media screen and (max-width: 480px) {
            display: none;
        }
        .box {
            padding: 40px 40px 0 40px;;
            margin: 0;
            &:last-of-type {
                padding-bottom: 40px;
            }
            .boxhead {
                border-bottom: 0;
                margin-bottom: 1em;
                span {
                    margin-top: 0;
                    font-size: 20px;
                    font-weight: bold;
                    color: @greenColor;
                }
            }
        }

        #box_menu {
            ul {
                li {
                    margin: 5px 0;

                    a {
                        color: #000;
                        font-weight: bold;
                    }
                    ul {
                        margin-left: 5px;
                        li {
                            list-style-type: none;
                            margin: 3px 0;
                            a {
                                font-weight: normal;
                                font-size: 13px;
                            }
                        }
                    }
                    &.current {
                        > a {
                            color: @greenColor;
                        }
                    }
                    &#category_promo {
                        .promo {
                            color: @redColor;
                        }
                    }
                }
            }
        }
    }
} 