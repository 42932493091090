#box_filter {
    .innerbox {
        .filter-name {
            width: 100%;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        .multiselect {
            width: 100%;
            margin-bottom: 25px;
            background: #ffffff;
            color: #000000;
            border: 0;
            font-size: .9em;
            &::after {
                content: "\e910";
                font-family: Frino!important;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                position: absolute;
                top: 0;
                right: 0;
                padding: 12px;
                background: @yellowColor;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            > span {
                padding: 0 .5em;
            }
        }
    }
}