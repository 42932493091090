.footer__newsletter {
   //width: 22%!important;
    .newsletter {
        padding-top: 3.5em;
        padding-bottom: 3.5em;
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 767px) {
            justify-content: center;
            align-items: center;
            padding: 1em 0;
        }
        &__header {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: bold;
            padding-bottom: 15px;
            margin-bottom: 1px; 
        }
        form {
            display: flex;
            flex-direction: column;
            max-width: 316px;
            input[type=text] {
                width: 63%;
               // max-width: 200px; 
                height: 45px;
                padding: 5px 10px;
                margin-bottom: 0;
                background-color: rgba(235, 235, 235, 0.349);
                color: #fff;
                font-size: 12px; 
                &::placeholder {
                    color: #fff;
                    font-size: 12px; 
                }
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }

            .rodo-wrapper {
                display: flex;
                align-items: flex-start;
                margin-top: 15px;
                padding: 0;
                .rodo-checkbox { 
                    position: relative;
                    margin-bottom: 0;
                    border: 1px solid #fff;
                    background: transparent;
                    box-shadow: none;
                    width: 20px;
                    height: 20px;
                    padding: 9px;
                    -webkit-appearance: none; 
                    &::before {
                        content: '';
                        background: @yellowColor;
                        width: 14px;
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        height: 14px;
                        opacity: 0;
                        transition: opacity 0.2s ease-in-out;
                    }
                    &:checked::before {
                        opacity: 1;
                    }
                } 
                .rodo2 {
                    font-size: 10px;  
                    line-height: 1.5;
                    text-align: justify;
                    padding-left: 15px;
                }

            }
            .btn {
                background-color: @yellowColor;
                border-color: @yellowColor;
                height: 45px;
                justify-content: center;
                align-items: center;  
                text-transform: uppercase;
                font-weight: bold; 
                font-size: 14px;
                padding: 0 27px;
                transition: .5s ease;
                &:hover {
                    opacity: .8;
                }
                @media screen and (max-width: 1625px) { 
                    padding: 0 20px;
                }
                @media screen and (max-width: 767px) {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}
