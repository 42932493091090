.mainheader__logo {
    padding: 16px 40px;
    background: @greenColor;
    min-width: 200px;
    @media screen and (max-width: 1625px) {
        padding: 21px 15px;
        flex-basis: calc(100%/4); 
    }
    @media screen and (max-width: 1487px) {
        padding: 22px 15px;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}