.bottom-footer {
    display: flex;
    @media screen and (max-width: 1150px) {
        flex-direction: column;
    }
    &__wrapper {
        display: flex;
        width: 100%;
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}