.layout {
    &__wrapper {
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px; 
        @media screen and (max-width: 480px) {
            padding: 0;
        }
    }
}

.fake-container {
    &--third {
        flex-basis: calc(100% / 3);
        @media screen and (max-width: 1150px) {
            flex-basis: 0;
        }
    }
} 