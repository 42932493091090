#shoper-foot {
    display: none;
}

.up {
    display: none;
}

.footer__copyright {
    padding: 0 45px;

    .copyright {
        display: flex;
        justify-content: space-between;
        margin: 60px 0 30px;
        align-items: flex-end;
        @media screen and (max-width: 767px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &__company {
            display: flex;
            align-items: flex-end;
            @media screen and (max-width: 767px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 1rem;
            }
            .agroplast {
                display: flex;
                flex-direction: column;
                margin-right: 40px;
                @media screen and (max-width: 767px) {
                    margin-right: 0;
                }
                span {
                    margin-top: 10px;
                    color: #fff; 
                    font-size: 12px;
                }
            }
            .shoper {
                > a {
                    font-size: 12px;
                    color: rgba(255,255,255,.5);
                }
            }
        }

        &__getecom { 
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                color: #fff;
                font-weight: bold;
                font-size: 12px;
                margin-right: 10px;
            }
            img { 
                width: 100%; 
                min-width: 100px; 
                max-width: 100px;   
            } 
        } 
    }
}