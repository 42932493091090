/*
.menu {
    padding: 0;
    .innermenu {
        height: 80px;
        display: flex;
        align-items: center;
        .menu-list {
            border-bottom: 0;
            height: auto;
            > li { 
                height: 80px;
                a {
                    font-size: 16px;
                    color: #000;
                    @media screen and (max-width: 1725px) {
                        font-size: 15px;
                        padding: 0.3em 1em!important;
                    }
                    @media screen and (max-width: 1625px) {
                        font-size: 14px;
                        padding: 0.3em .8em!important;
                    }
                    &:hover {
                        text-decoration: none!important;
                    }
                }
                &.parent {
                    background: @yellowColor;
                    > h3 {
                        a { 
                            color: #fff;
                            display: flex;
                            align-items: center;
                            font-weight: bold;
                            &::after {
                                content: "\e910";
                                font-family: Frino!important;
                                font-size: 13px;
                                color: @greenColor;
                                background: #fff;
                                border-radius: 50%;
                                padding: 2px 2px 1px;
                                line-height: 1;
                                margin-left: 10px;
                                box-shadow: 0px 4px 7px 0px rgba(1, 1, 1, 0.2);
                            }
                              
                        } 
                    }

                    .submenu { 
                        ul.level1 {
                            position: absolute;
                            z-index: 1;
                            background-color: #fff;
                            border: 1px solid @lightColor;
                            width: 40%;
                            top: 80px;
                            li {
                                height: 40px;
                                h3 {
                                    height: 40px;
                                    a {
                                        color: #000;
                                    }
                                }
                            }
                        }
                    }
                }
                h3 {
                    margin-top: 0;
                    height: 80px;
                    display: flex; 
                    align-items: center;
                }
            } 
        }
    }
}
*/

.navigation--menu {
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

 
.menu {
    display: flex;

    position: relative;

    padding: 0;
    margin: 0;

    transition: .5s ease-in-out;

    @media screen and (max-width: 650px) {
        display: none;

        &--active {
            display: flex;
            transition: .5s ease-in-out;
        }
    }

    @media screen and (max-width: 650px) {
        flex-direction: column;
    }
    
    @media screen and ( max-width: 880px ) {
       // background: fade( @backgroundColor, 90% );
       // padding: 20px 0;
      //  border: 1px solid @lightBorderColor;
    }

    // First level menu bar
    > .item {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 80px;

        @media screen and ( max-width: 880px ) {
            //display: block;
        }
        @media screen and (max-width: 650px) {
            height: 45px;
        }

        > .item__link {
            display: block;
            padding: 10px 20px;
            font-size: 16px;
            color: #000;
            &:hover {
                color: @greenColor;
            }
            @media screen and (max-width: 1725px) {
                font-size: 15px;
                padding: 0.3em 1em!important;
            }
            @media screen and (max-width: 1625px) {
                font-size: 14px;
                padding: 0.3em .8em!important;
            }
            &:hover {
                text-decoration: none!important;
            }
            @media screen and ( max-width: 880px ) {
                text-align: left;
            }

            transition: color 0.3s ease-in-out,
                        background 0.3s ease-in-out;

            @media screen and ( min-width: 881px ) {
                &:hover {
             //       color: @backgroundColor;
                  //  background: @greenColor;
    
                    opacity: 1;
                }
            }

            .item__label {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
            }
        }

        &.item--parent {
            background: @yellowColor;
            @media screen and (max-width: 650px) {
                display: block;
            }
            > .item__link {
                display: flex;
                transition: color 0.3s ease-in-out 0.3s,
                            background 0.3s ease-in-out 0.3s;
                            color: #fff;
                           
                            &:hover { 
                                color: @greenColor;
                            }
                            > .item__label {
                                font-weight: bold;
                            }
                            @media screen and (max-width: 650px) {
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 45px;
                            }
            }
            .item__icon {
                font-size: 13px;
                font-weight: bold;
                color: @yellowColor;
                background: #fff;
                border-radius: 50%;
                padding: 3px 3px 0px;
                line-height: 1;
                margin-left: 10px;
                box-shadow: 0px 4px 7px 0px rgba(1, 1, 1, 0.2);
                transition: transform 0.3s ease-in-out 0.3s;
            }
            
            @media screen and ( min-width: 0px /*881px*/ ) {

            }

            @media screen and (min-width: 651px) {
                &:hover {
                    > .item__link {
                        //background: @greenColor;
                        color: @greenColor;
                        opacity: 1;
                        
                        transition: color 0.3s ease-in-out,
                                    background 0.3s ease-in-out;
                    }
    
                    .item__icon {
                        transform: rotate( 180deg );
                        transition: transform 0.3s ease-in-out;
                    }

                }
                &:hover {
                    .submenu__wrapper {
                        visibility: visible!important;
                        opacity: 1!important;
    
                        transition: visibility 0s linear 0s,
                                    opacity 0.3s ease-in-out;
                    }
                }
            }
        }
    }
}


.submenu {
    &__wrapper {
        @media screen and ( min-width: 0px /*881px*/ ) {
            position: absolute;
            top: 100%;
            left: 0;
            //width: 100%;
            z-index: 100;

            width: 200%;
            max-width: 1390px;
    
            visibility: hidden;
            opacity: 0;
            
            transition: visibility 0s linear 0.6s,
                        opacity 0.3s ease-in-out 0.3s;
        }
        @media screen and (max-width: 1405px) {
            width: auto;
            max-width: auto;
        }
        @media screen and ( max-width: 880px ) {
            
        }
        @media screen and (max-width: 650px) {
            position: relative;
            margin-top: -45px;
        }

        &--visible {
            visibility: visible!important;
            opacity: 1!important;
        }
    }

    display: flex;
    // justify-content: space-between;

    text-align: left;
    background: rgba(0,0,0,.9);
    padding: 20px 0;
   // border: 1px solid @lightBorderColor;

    @media screen and ( max-width: 1150px ) {
        display: block;
        //background: transparent;
        border: 0;
    }

    .item {
        .item__link {
            display: block;
            color: #fff;
            padding: 5px 0;

            &:hover {
                color: @greenColor;
            }
 
            .item__label {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                &.subcategory {
                    margin-left: 15px;
                }
                &.sonderangebote {
                    color: @redColor;
                }
            }
        }
    }

    .group {
        flex-basis: 25%;

        padding: 0 45px;

        &:last-child {
            flex-grow: 1;
        }
        &:not( :last-child ) {
      //      border-right: 1px solid @lightBorderColor;
        }
        .group__header {
            //margin-bottom: 5px;
            @media screen and ( max-width: 880px ) {
                margin-bottom: 0;
            }

            .item__label {
                font-weight: 700;
                font-size: 16px;
               // text-transform: uppercase;
            }
        }
        .group__items {
            @media screen and ( max-width: 880px ) {
                padding-left: 25px;
            }
        }
        &[data-category-id="000"] {
            .group__items {
                columns: 2;

                @media screen and ( max-width: 880px ) {
                    columns: unset;
                }
            }
        }
    }
}