#like-box {
    position: fixed;
    z-index: 99999;
    top: 47%;
    right: -345px;
    transition: right 0.5s ease-out;
    &:hover {
        right: 0;
    }

    .belt {
        position: relative;
        z-index: 0;
        float: left;
        text-align: center;
        width: 65px;
        height: 65px;
        padding: 0;
        margin: 50px 0 0 -70px;
        background: #3b5999;
        color: #fff;
        font-weight: bold;
        font-size: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
  }
